
/***
 * Funcion que recibe un evento y el valor . Dicho valor se pasa como variable global y luego se recoge para meterlo
*/
export function captureLocationCta(event, valueLocation) {
    const btn = event.target;
    const btnPropagation = btn.getAttribute("data-analytics-element");
    console.log("Boton propacion " + btnPropagation)
    globalThis.location_cta_personalizacion=valueLocation || " ";
    return valueLocation
}