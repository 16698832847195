import { getEnviroment, getQueryVariable, loadScript } from './utils/utils'
import {
  getAdformId,
  getClient,
} from './utils/functionsTracking/functionsTracking'
import * as globalVar from './globalvars_Config/globalvars'
import { loadConversion } from './utils/funtionsRefactor/loadConversion'
import { loadConversionConbertura } from './utils/funtionsRefactor/loadConversionConbertura'
import { getUrl } from './utils/funtionsRefactor/getUrl'
import { getLeadId } from './utils/funtionsRefactor/getLeadId'
import { getISP } from './utils/funtionsRefactor/getISP'
import { getRingPoolService } from './utils/funtionsRefactor/getRingPoolService'
import { addFormField } from './utils/funtionsRefactor/addFormField'
import { addField_url } from './utils/funtionsRefactor/addField_url'
import { addField_AdformID } from './utils/funtionsRefactor/addField_AdformID'
import { pixelLog } from './utils/funtionsRefactor/pixelLog'
import { getUrlNotifyD3P } from './utils/funtionsRefactor/getUrlNotifyD3P'
import { notifyD3P } from './utils/funtionsRefactor/notifyD3P'
import { getInterfaceMode } from './utils/funtionsRefactor/getInterfaceMode'
import { isComprafacilInteface } from './utils/funtionsRefactor/isComprafacilInteface'
import { addDeclarativeField } from './utils/funtionsRefactor/addDeclarativeField'
import { addField_declarativaUrl } from './utils/funtionsRefactor/addField_declarativaUrl'
import { addListenerInputsNumber } from './utils/funtionsRefactor/addListenerInputsNumber'
import { addTrackingProcess } from './utils/funtionsRefactor/addTrackingProcess'
import { adformLoader } from './utils/funtionsRefactor/adformLoader'
import { loadAllLibs } from './utils/funtionsRefactor/loadAllLibs'
import { getCookie } from './utils/funtionsRefactor/getCookie'
import { getPlacement } from './utils/funtionsRefactor/getPlacement'
import { selectUrl } from './utils/funtionsRefactor/selectUrl'
import { isDMPCartera } from './utils/funtionsRefactor/isDMPCartera'
// import { declarativaPorUrl } from './utils/funtionsRefactor/declarativaPorUrl'
import {
  addLabelsWalmeric,
  initDrpTienda,
  initModal,
} from './support/tiendaNew'
import { getDataLeadRingpool } from './utils/functionsTracking/functionsRingpool'
import { getDevice } from './utils/funtionsRefactor/getDevice'
import {
  setVisitVars,
  setConversionVars,
} from './utils/functionsTracking/functionConversions'
// import ErrorWalmeric from './utils/errorControl/errorWalmericPersonalized'
// import { objectErrorWm } from './utils/errorControl/objectErrorWalmeric'
import { watchCookie } from './utils/functionsCookies/observerCookie'
import { declarativeByUrlBinary } from './utils/funtionsRefactor/declarativeByUrlBinary'
import { loadEspt } from './utils/functionsPlatform/loadEpst'
import { adobe_teallium } from './utils/functionsPlatform/adobe_teallium'
import { getLocationCtaCallmeRefactor } from './utils/funtionsRefactor/getLocationCtaCallmeRefactor'
import { getLocationCtaRingpoolRefactor } from './utils/funtionsRefactor/getLocationCtaRingpoolRefactor'
import { velocityInternet } from './utils/funtionsRefactor/velocityInternet'
import { captureLocationCta } from './utils/functionsAnalytics/captureLocationCta'


/*
  Cambiar el value del input a Cargando recursos... para que el usuario entienda que todavia no puede completar el form
*/
/*async function changeValueInput(){
  const internet =  await velocityInternet();
  if(internet<=2){
    let bttTeLlamamos = document.getElementsByClassName("ctc-form-banner")[0];
    if(bttTeLlamamos instanceof HTMLInputElement){
      bttTeLlamamos.disabled=false;
      bttTeLlamamos.value="Cargando recursos...";
    }
  }
}

changeValueInput();*/


/* OBJETO GLOBAL DFI */
export const DFI = {
  clientConfig: globalVar.clientConfig,
  entorno: getEnviroment,
  global: globalVar.global,
  rpServices: globalVar.rpServices,
  trackingParams: globalVar.trackingParams,
  getDevice: getDevice,
  declarativeByUrlBinary: declarativeByUrlBinary,
  getPlacement: getPlacement,
  selectUrl: selectUrl,
  isDMPCartera: isDMPCartera,
  getCookie: getCookie,
  getClient: getClient,
  getDataLeadCallMe: addLabelsWalmeric,
  getDataLeadRingpool: getDataLeadRingpool,
  loadConversion: loadConversion,
  loadConversionConbertura: loadConversionConbertura,
  getUrl: getUrl,
  getLeadId: getLeadId /* ?????? */,
  getISP: getISP,
  getRingPoolService: getRingPoolService,
  addFormField: addFormField,
  addField_url: addField_url,
  addField_AdformID: addField_AdformID,
  pixelLog: pixelLog,
  getUrlNotifyD3P: getUrlNotifyD3P,
  notifyD3P: notifyD3P,
  getInterfaceMode: getInterfaceMode,
  isComprafacilInteface: isComprafacilInteface,
  addDeclarativeField: addDeclarativeField,
  addField_declarativaUrl: addField_declarativaUrl,
  addListenerInputsNumber: addListenerInputsNumber,
  addTrackingProcess: addTrackingProcess,
  adformLoader: adformLoader,
  addLabelsWalmeric: addLabelsWalmeric,
  captureLocationCta:captureLocationCta
}



/* ENTORNOS A CARGAR DE WALMERIC  */
const entornosWalmeric = {
  localHost: location.protocol + '//' + location.host,
  vodafone: '',
}

/*ENTORNOS POR DEFECTO A CARGAR EN ESTE MOMENTO CARGAMOS D AL ESTAR VACIO SIGUE CON LA CARGA */

const entornoActual = entornosWalmeric[DFI.entorno()]

console.log('Estas en el entorno de ' + DFI.entorno() + ': ' + entornoActual)

/*PASO 1 : CARGAMOS LIBRERIAS DELIO*/

/* Carga varios scripts de la libreria de walmeric  mediante un array de promesas 
   REVISION : globalVar.global.lht revisar si es necesario cargarlo parece que ya no esta en uso
*/

const libs = [
  { url: globalVar.global.dal },
  { url: globalVar.global.dcl },
  { url: globalVar.global.dvl },
  { url: globalVar.global.det },
  { url: globalVar.global.tagVisit[getClient()] },
  { url: globalVar.global.drp },
]

const initTienda = () => {
  setConversionVars()
  setVisitVars()
  initDrpTienda()
}

initModal()

/* Código para cargar las bibliotecas */
loadAllLibs(libs)
  .then(() => {
    return getAdformId()
  })
  .then((adformUid) => {
    loadEspt(adformUid)
    initTienda()
    watchCookie('OptanonConsent', 'groups', adformUid)
  }).catch((error) => {
      console.error(error)
  })


// loadAllLibs(libs)
//   .then(async () => {
//     /*Cuando se resuleve el adForm Uuid , se la pasamos al observer par actualizar las etiquetas */

//       // watchCookie('OptanonConsent', "groups", adformUid,function (newValue) {
//       //   console.log('El valor de la cookie ha cambiado a:', newValue);
//       // })
//       // /*Cargamos el epst*/
//       const adformUid= await getAdformId()
//       console.log(adformUid)

//   }).then(()=>{
//      getAdformId()
//   }).then(()=>{
//     initTienda()
//   })
//   .catch((error) => {
//     console.log(error)
//   })

/* Variable global para ver todo el objeto DFI */
globalThis.clientConfig = DFI.clientConfig
globalThis.Walmeric = DFI || {}
globalThis.DFI = { getCookie: getCookie }
getLocationCtaCallmeRefactor()
getLocationCtaRingpoolRefactor()
