/**
 * Funcion encargada de añadir el evento responsable de recoger el valor de la localizacion
 * del rinpool, la cual se encuetra en el atributo data-analytics-location del rinpool.
 */
export const getLocationCtaRingpoolRefactor = () => {
    const location_cta = document.querySelectorAll(".drp-phone");
    
    if(location_cta){
        location_cta.forEach((element) =>
            element.addEventListener('mousedown', function(){
                globalThis.location_cta = element.getAttribute("data-analytics-location");
            })
        )
    }

};